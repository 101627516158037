import React, { useState } from "react";
import { Link } from "gatsby";
import { Location } from "@reach/router";
import logo from "../img/logo.svg";
import styled from "styled-components";
import { mediaQuery } from "./_breakpoints";
import { colorsGlobal } from "./_global";

const Navbar = ({ setActive, isMenuActive }) => {
  const toggleHamburger = () => {
    setActive((prev) => (prev === "active" ? "inactive" : "active"));
  };

  const Links = ({ active, isMobile }) => (
    <Location>
      {({ location }) => {
        return (
          <LinkWrapper active={active}>
            <LinkStyled
              to="/articles"
              active={location.pathname === "/articles"}
            >
              Aktualności
            </LinkStyled>
            <LinkStyled to="/films" active={location.pathname === "/films"}>
              Filmy
            </LinkStyled>
            <LinkStyled to="/faq" active={location.pathname === "/faq"}>
              FAQ
            </LinkStyled>
            <LinkStyled
              to="/about-us"
              active={location.pathname === "/about-us"}
            >
              O nas
            </LinkStyled>
            <LinkStyled to="/contact" active={location.pathname === "/contact"}>
              Kontakt
            </LinkStyled>
            {isMobile && (
              <LinkStyled as="a" href="javascript:void(0)" disabled>
                Sklep
              </LinkStyled>
            )}
          </LinkWrapper>
        );
      }}
    </Location>
  );

  return (
    <NavBar>
      <Location>
        {({ location }) => (
          <>
            <Mobile active={isMenuActive === "active"} isHomePage={false}>
              <MobileTop>
                <LinkLogo to="/" title="Logo" isMobile>
                  <LogoImg
                    src={logo}
                    alt="Wojna Idei"
                    isHomePage={location.pathname === "/"}
                    isMobile
                  />
                </LinkLogo>
                <StyledHamburger
                  isOpen={isMenuActive === "active"}
                  onClick={() => toggleHamburger()}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </StyledHamburger>
              </MobileTop>
              <LinksWrapperMobile active={isMenuActive === "active"}>
                <Links active={isMenuActive} isMobile />
              </LinksWrapperMobile>
            </Mobile>
            <Desktop>
              <LinkLogo to="/" title="Logo">
                <LogoImg src={logo} alt="Wojna Idei" />
              </LinkLogo>
              <Links />
            </Desktop>
          </>
        )}
      </Location>
    </NavBar>
  );
};

const NavBar = styled.nav`
  display: flex;
  z-index: 999;
  width: 95%;
  margin-top: 20px;
  justify-content: flex-end;
  top: 0;
  position: fixed;
  ${mediaQuery.sm(`
    width: 87%;
    margin-top: 40px;
      justify-content: flex-start;
    padding: 0;
    margin-bottom: 30px;
  `)}
`;

const Desktop = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  ${mediaQuery.sm(`
    display: inline-flex;
    width: initial;
    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: -10px;
    border-radius: 5px;
    padding-left: 5px;
    max-height: 25px;
  `)}
`;

const LinkLogo = styled(Link)`
  display: flex;
  ${(props) =>
    props.isMobile &&
    `
  width: 100%;
  `}
`;

const LogoImg = styled.img`
  width: 200px;
  height: 100%;
  ${(props) =>
    !props.isHomePage || props.isMobile ? "filter: brightness(0);" : null}
  ${mediaQuery.lg(`
    width: 150px;
  `)}
`;

const Mobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => (props.isHomePage ? "auto" : "100%")};
  padding: 0 7px;
  padding-bottom: 7px;
  background-color: ${(props) =>
    props.active ? "rgb(255,255,255)" : "rgba(255, 255, 255, 0.5)"};
  border-radius: 5px;
  ${(props) =>
    props.active &&
    `
  border: 2px solid rgba(110,46,229, 0.7);
  margin: -2px;
  `}
  ${mediaQuery.sm(`
    display: none;
  `)}
`;

const LinksWrapperMobile = styled.div`
  display: flex;
  // opacity: ${(props) => (props.active ? 1 : 0)};
  //transition: opacity 0.1s ease-in;
`;

const StyledHamburger = styled.div`
  display: flex;
  width: ${(props) => (props.isOpen ? "60px" : "60px")};
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg) scale(0.6);
  -moz-transform: rotate(0deg) scale(0.6);
  -o-transform: rotate(0deg) scale(0.6);
  transform: rotate(0deg) scale(0.6);
  -webkit-transition: transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }
  span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    outline: none;
    background: black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.125s ease-in-out;
    -moz-transition: 0.125s ease-in-out;
    -o-transition: 0.125s ease-in-out;
    transition: 0.125s ease-in-out;
    &:focus,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
    }
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 18px;
  }

  span:nth-child(4) {
    top: 36px;
  }

  ${(props) =>
    props.isOpen &&
    `
  span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
`}
`;

const LinkWrapper = styled.div`
  display: ${(props) =>
    !!props.active ? (props.active === "active" ? "flex" : "none") : "flex"};
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  ${mediaQuery.sm(`
    flex-direction: row;
    padding-left: 0;
    width: initial;
    position: relative;
    top: 0;
    margin-left: 30px;
  `)}
`;

const LinkStyled = styled(Link)`
  display: flex;
  position: relative;
  width: fit-content;
  height: min-content;
  justify-content: flex-start;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${props => props.disabled ? '#afafaf' : "#e3d3ff"};
  font-size: 18px;
  margin: 5.5px 5px;
  border-radius: 5px;
  padding: 2px 8px 2px 12px;
  font-family: "Source Sans Pro", sans-serif;
  ${mediaQuery.sm(`
    justify-content: flex-start;
    background-color: transparent;
    clip-path: none;
    width: fit-content;
    font-weight: 600;
    font-size: 18px;
    padding: 0;
    text-transform: uppercase;
    margin: 0 10px;
    &:hover{
      color: ${colorsGlobal.primary};
    }
  `)}
  ${(props) =>
    props.active &&
    `
    background: #C9ADFF;
    color: black;
    box-shadow: inset 6px 0 ${colorsGlobal.primary};

    ${mediaQuery.sm(`
    color: black;
    background: transparent;
    box-shadow: none;
    color: ${colorsGlobal.primary};
      font-weight: bold;
      `)}`};
`;

const MobileTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export default Navbar;
