import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SocialLink from "../../components/ActionButtons/SocialLink";
import { mediaQuery } from "../../components/_breakpoints";

const socialButtons = [
  { name: "instagram", nodes: [], href: "https://www.instagram.com/wojnaidei/" },
  { name: "twitter", nodes: [], href: "https://twitter.com/wojna_idei" },
  { name: "facebook", nodes: [], href: "https://www.facebook.com/wojnaidei/" },
];

const SocialButtons = ({backgroundColor}) => {
  const [isActive, setActive] = useState("");
  const [activeTabElements, setActiveTabElements] = useState({});

  useEffect(() => {
    const newActive = socialButtons.find((el) => el.name === isActive);
    setActiveTabElements(newActive);
  }, [isActive]);

  return (
    <SocialButtonsWrapper>
      <SemiSocialButtonsWrapper isActive={isActive}>
        {activeTabElements?.nodes?.map((el) => {
          return (
            <SemiSocialLink
              {...el}
            />
          );
        })}
      </SemiSocialButtonsWrapper>
      {socialButtons.map((el) => (
        <SocialLink
          {...el}
          isActive={el.name === isActive}
          {...(backgroundColor ? {backgroundColor: backgroundColor} : null)}
          handleClick={(data) =>
            data === isActive ? setActive("") : setActive(data)
          }
        />
      ))}
    </SocialButtonsWrapper>
  );
};

const SocialButtonsWrapper = styled.div`
  display: flex;
  position: relative;
  bottom: 0;
  right: 0;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
  ${mediaQuery.sm(`
    flex-wrap: no-wrap;
    justify-content: flex-end;
    z-index: 20;
    width: auto;
  `)}
  a {
    margin: 3px;
  }
`;

const SemiSocialLink = styled(SocialLink)`
  font-weight: 700;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 16px;
  margin: 3px;
  ${mediaQuery.sm(`
    font-size: 10px;
    height: 10px;
    line-height: 10px;
    padding: 10px 20px;
    width: auto;
  `)}
`;

const SemiSocialButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  transform: translateY(-100%);
  ${mediaQuery.sm(`
    transform: translateY(100%);
    transform: translateX(-100%);
    left: 0;
  `)}
`;

export default SocialButtons;
