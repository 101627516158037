import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import Navbar from "../Navbar";
import useSiteMetadata from "../SiteMetadata";
import { graphql, StaticQuery, withPrefix } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";
import { mediaQuery } from "../_breakpoints";
import bckgdImg from "../../img/shapeBackground.svg";
import bckgdImg2 from "../../img/shape2.svg";

const TemplateWrapper = ({ children, articles, wojnaIdeiYt, titleData }) => {
  const { title, description } = useSiteMetadata();

  const [isHomePath, seIsHomePath] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isMenuActive, setActive] = useState("inactive");

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname === "/") {
      seIsHomePath(true);
    }
  }, []);

  return (
    <AppWrapper>
      <Reset />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noticia+Text:ital,wght@1,700&display=swap"
          rel="stylesheet"
        />
        <script
          async
          defer
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"
        />
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charSet="utf-8"
        />
        <meta name="theme-color" content="#000" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.png`}
        />
      </Helmet>
      <GlobalStyle />
      <LayoutWrapper>
        <Navbar setActive={setActive} isMenuActive={isMenuActive} />
        <BackgroundWrapper>
          {/*<BackgroundImageLeft src={bckgdImg} />*/}
          {/*<BackgroundImageRight src={bckgdImg2} />*/}
          <PageContainer>
            <PageWrapper>{children}</PageWrapper>
          </PageContainer>
        </BackgroundWrapper>
        {isHomePath && (
          <>
            <Footer
              articles={articles}
              wojnaIdeiYt={wojnaIdeiYt}
              handleFooter={(value) => setFooterHeight(value)}
            />
            <Box boxHeight={footerHeight} id="bottom" />
          </>
        )}
      </LayoutWrapper>
    </AppWrapper>
  );
};

export default ({ ...args }) => (
  <StaticQuery
    query={graphql`
      query LatestArticlesFooter {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            title
            subtitle
          }
        }
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "article-page" } } }
          limit: 5
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
        wojnaIdeiYt {
          data {
            items {
              etag
              id {
                kind
                videoId
                channelId
                playlistId
              }
              kind
              snippet {
                channelId
                channelTitle
                description
                liveBroadcastContent
                publishTime
                publishedAt
                title
                thumbnails {
                  default {
                    height
                    url
                    width
                  }
                  high {
                    height
                    url
                    width
                  }
                  medium {
                    height
                    url
                    width
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <TemplateWrapper
        articles={data.allMarkdownRemark.edges}
        wojnaIdeiYt={data.wojnaIdeiYt.data.items}
        titleData={data.markdownRemark.frontmatter}
        {...args}
      />
    )}
  />
);

const PageWrapper = styled.div`
  flex-grow: 1;
  max-width: 1440px;
  width: 100%;
  display: flex;
  z-index: 3;
  ${mediaQuery.sm(`
    max-width: 95%;
  `)}
  ${mediaQuery.xl(`
    max-width: 87%;
  `)}
`;

const BackgroundWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  z-index: 900;
  width: 100%;
  overflow: hidden;
  top: 0;
`;

const GlobalStyle = createGlobalStyle` 
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  min-height: 100vh;
  top: 0;
  z-index: 2;
  width: 100%;
  ${mediaQuery.lg`
    // clip-path: polygon(0% 0%,100% 0%,100% 100%,59% 100%,56% 97%,44% 97%,41% 100%,0% 100%);
  `}
`;

const BackgroundImageLeft = styled.img.attrs({ className: ".b" })`
  display: flex;
  opacity: 0.4;
  position: absolute;
  left: -6.5%;
  max-width: 200px;
  min-width: 200px;
  width: 100%;
  margin-top: 0px;
  ${mediaQuery.lg`
    max-width: 200px;
    z-index: 0;
    left: 200px;
  `}
`;

const BackgroundImageRight = styled.img.attrs({ className: ".b" })`
  display: flex;
  opacity: 0.4;
  position: absolute;
  right: -6.5%;
  max-width: 250px;
  min-width: 150px;
  width: 100%;
  top: 0;
  margin-top: 370px;
  ${mediaQuery.lg`
    max-width: 350px;
    z-index: 0;
    bottom: 10%;
    right: 0;
  `}
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  div,
  p,
  a,
  span,
  button {
    font-family: "Source Sans Pro", sans-serif;
  }
`;

const Reset = createGlobalStyle`
  body {
    margin: 0;
    height: 100%;
  }
  html { height: 100%; overflow:auto; }
`;

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Box = styled.div`
  display: none;

  ${({ boxHeight }) =>
    mediaQuery.xl(`
    display: block;
    height: ${boxHeight}px;
    width: 100%;
    background-color: transparent;
  `)}
`;
