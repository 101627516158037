const size = {
  xs: "320px",
  sm: "769px",
  lg: "960px",
  xl: "1152px",
  xxl: "1344px",
};

const mediaQuery = {
  xs: (css) => `@media only screen and (min-width: ${size.xs}){${css}}`,
  sm: (css) => `@media only screen and (min-width: ${size.sm}){${css}}`,
  lg: (css) => `@media only screen and (min-width: ${size.lg}){${css}}`,
  xl: (css) => `@media only screen and (min-width: ${size.xl}){${css}}`,
  xxl: (css) => `@media only screen and (min-width: ${size.xl}){${css}}`,
};

export { size, mediaQuery };
