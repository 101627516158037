const colorsGlobal = {
  primary: "#A645DE",
  primaryLight: "#cca6ff",
  primaryLight2: "#e1ccff",

  secondary: "#F2BC0F",

  black: "#090C08",
  third: "#ebe5ee",
  fourth: "#C6E2E9",
  fif: "#FFCAAF",
};

const socialButtonsColors = {
  wesprzyj: colorsGlobal.primary, //"#dc5eff",
  patronite: colorsGlobal.primary,
  youtube: "#FF6363", //"#FF6363",
  instagram: "#FF6363", //"#FF6363",
  wykop: colorsGlobal.primary, //"#e75d37",
  cda: colorsGlobal.primary, //"#ff800b",
  paypal: colorsGlobal.primary, //"#35ffd6",
  "konto bankowe": colorsGlobal.primary, // "#b8ff35",
  twitter: "#4DBFFF", // "#4DBFFF",
  facebook: "#5C6DFF", // "#5C6DFF",
  discord: colorsGlobal.primary, // "#4E71E8",
  default: colorsGlobal.primary,
  transparent: "white",
};


const socialButtonsColorsHover = {
  // wesprzyj: colorsGlobal.primary, //"#dc5eff",
  // patronite: colorsGlobal.primary,
  // youtube: "#FF6363", //"#FF6363",
  // instagram: "#FF6363", //"#FF6363",
  // wykop: colorsGlobal.primary, //"#e75d37",
  // cda: colorsGlobal.primary, //"#ff800b",
  // paypal: colorsGlobal.primary, //"#35ffd6",
  // "konto bankowe": colorsGlobal.primary, // "#b8ff35",
  // twitter: "#4DBFFF", // "#4DBFFF",
  // facebook: "#5C6DFF", // "#5C6DFF",
  // discord: colorsGlobal.primary, // "#4E71E8",
  default: '#BD90F5',
  transparent: "white",
};

const socialButtonsColorsLight = {
  // wesprzyj: colorsGlobal.primaryLight, //"#ec9aff",
  // patronite: colorsGlobal.primaryLight, //"#ffc996",
  // youtube: colorsGlobal.primaryLight, //"#ff9e9e",
  // instagram: colorsGlobal.primaryLight, //"#FF6363",
  // wykop: colorsGlobal.primaryLight, //"#ffac95",
  // paypal: colorsGlobal.primaryLight, //"#9afde9",
  // direct: colorsGlobal.primaryLight, //"#deff9c",
  // twitter: colorsGlobal.primaryLight, //"#9edeff",
  // facebook: colorsGlobal.primaryLight, //"#9ca6ff",
  // discord: colorsGlobal.primaryLight, //"#98afff",
  default: "white", //"#a56fff"
};

const socialLinksColors = {
  purple: {
    background: colorsGlobal.primaryLight,
    "border-color": colorsGlobal.primary,
  },
  yellow: {
    background: "#ffe295",
    "border-color": "#ffba00",
  },
};

const footerColors = {
  footerBackground:
    "linear-gradient(180deg, rgba(204,166,255,1) 0%, rgba(204,166,255,1) 100%);",
  title: "#e6e6e6",
  link: "#e6e6e6",
  footerCredits: "#e6e6e6",
};

export {
  colorsGlobal,
  socialButtonsColors,
  socialLinksColors,
  socialButtonsColorsLight,
  socialButtonsColorsHover,
  footerColors,
};
