import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { mediaQuery } from "./_breakpoints";
import wojnaIdeiLogo from "../img/background-bulb.svg";
import { Link } from "gatsby";
import { colorsGlobal, footerColors } from "./_global";
import SocialButtons from "../modules/SocialButtons";
import bulbSrc from "../img/bulb.svg";

const Footer = ({ handleFooter, articles, wojnaIdeiYt }) => {
  const footerRef = useRef(null);

  const filmsLimited = wojnaIdeiYt.slice(0, 6);

  useEffect(() => {
    if (footerRef.current !== null) {
      handleFooter(footerRef.current.clientHeight);
    }
  }, [footerRef.current]);

  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, []);

  return (
    <TweenContainer ref={footerRef}>
      <FooterComponent>
        <BulbBackground src={bulbSrc} />
        <SectionsWrapper>
          <LastNewsWrapper>
            <Title>Twitter:</Title>
            <LastEntryContainer>
              <a
                key={"twitter-feed"}
                className="twitter-timeline"
                data-lang="pl"
                data-width="340"
                data-height="250"
                data-theme="light"
                data-chrome="nofooter noborders noheader"
                href="https://twitter.com/wojna_idei?ref_src=twsrc%5Etfw"
              />
            </LastEntryContainer>
          </LastNewsWrapper>
          {filmsLimited && (
            <Section>
              <Title>Ostatnie filmy:</Title>
              {filmsLimited.map(({ id: { videoId }, snippet: { title } }) => (
                <ListElement>
                  <LinkStyledHref
                    key={`${videoId}`}
                    href={`https://youtu.be/${videoId}`}
                  >
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </LinkStyledHref>
                </ListElement>
              ))}
            </Section>
          )}
          <Section>
            <Title>Aktualności:</Title>
            {articles.map(({ node }, index) => {
              return (
                <ListElement>
                  <LinkStyled
                    key={`${node.frontmatter.name}-${index}`}
                    to={node.fields.slug}
                  >
                    {node.frontmatter.title}
                  </LinkStyled>
                </ListElement>
              );
            })}
          </Section>
        </SectionsWrapper>
        <SiteRepresentation>
          <Logo src={wojnaIdeiLogo} />
          wojna idei 2021
        </SiteRepresentation>
        <SocialButtonsWrapper>
          <SocialButtons backgroundColor={colorsGlobal.primaryLight2} />
        </SocialButtonsWrapper>
      </FooterComponent>
    </TweenContainer>
  );
};

const TweenContainer = styled.footer.attrs({ className: "foo" })`
  width: 100%;
  overflow: hidden;
  z-index: 1;
  ${mediaQuery.xl(`
      position: fixed;
      margin-top: 0;
      bottom: 0;
      left: 0;
  `)}
`;

const SocialButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 100%;
  z-index: 51;
  ${mediaQuery.sm(`
    bottom: 0;
    position: absolute;
    justify-content: flex-end;
    right: 2.5%;
    bottom: 30px;
  `)}
  ${mediaQuery.xl(`
    right: 6.5%;
  `)}
`;

const FooterComponent = styled.footer`
  display: flex;
  flex-direction: column;
  background: rgb(12, 0, 73);
  padding: 0;
  width: 100%;
  position: relative;
  ${mediaQuery.lg(`
     margin-top: 25px;
     display: flex;
     flex-direction: column;
     align-items: center;
  `)}
  ${mediaQuery.xl(`
       margin-top: 0;
  `)}
`;

const SectionsWrapper = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  z-index: 51;
  flex-direction: column;
  justify-content: space-between;
  ${mediaQuery.lg(`
    margin: 20px 0;
    position: relative;
     flex-direction: row;
     width: 87%;
     z-index: 10;
  `)}
`;

const LastNewsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.lg(`
    margin-top: 0;
  `)}
`;

const Section = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 40px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.lg(`
    margin-top: 0;
    width: 30%;
  `)}
`;

const Title = styled.p`
  font-size: 24px;
  line-height: 30px;
  color: ${footerColors.title};
  margin: 9px 0;
  width: 95%;
  ${mediaQuery.sm(`
    width: 87%;
  `)}
  ${mediaQuery.lg(`
    width: 100%;
    text-align: left;
  `)}
`;

const ListElement = styled.li`
  width: 95%;
  margin: 3px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  ${mediaQuery.sm(`
    @supports (-webkit-line-clamp: 1) {
      -webkit-line-clamp: 1;
    }
    width: 87%;
    margin: 0;
  `)}
  ${mediaQuery.lg(`
    width: 100%;
    text-align: left;
    margin: 0;
  `)}
`;

const LinkStyled = styled(Link)`
  font-size: 16px;
  line-height: 23px;
  color: ${footerColors.link};
  text-decoration: none;
  font-style: italic;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const LinkStyledHref = styled.a.attrs({ target: "_blank" })`
  font-size: 16px;
  line-height: 23px;
  color: ${footerColors.link};
  text-decoration: none;
  font-style: italic;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const LastEntryContainer = styled.div`
  width: 100%;
  max-width: 340px;
  height: 200px;
  background-color: #fff;
  border-radius: 5px;
  ${mediaQuery.lg(`
    display: flex;
    position: relative;
    height: 250px;
    width: 340px;
  `)}
`;

const SiteRepresentation = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${footerColors.footerCredits};
  margin: 3px 0;
  z-index: 51;
  text-transform: uppercase;
  text-align: center;
  margin-top: 150px;
  ${mediaQuery.lg(`
    margin-top: -20px;
    margin-bottom: 10px;
  `)}
`;

const Logo = styled.img`
  height: 14px;
  width: 20px;
  margin-right: 2px;
`;

const BulbBackground = styled.img`
  display: flex;
  opacity: 0.6;
  position: absolute;
  right: 0;
  z-index: 50;
  bottom: 0;
  max-width: 200px;
  transform: rotateY(180deg);
  min-width: 200px;
  width: 100%;
  ${mediaQuery.lg`
    max-width: 400px;
    bottom: -200px;
    transform: rotateY(180deg) scale(1.5);
    z-index: 0;
  `}
`;

export default Footer;
