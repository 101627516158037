import React from "react";
import styled from "styled-components";
import {
  socialButtonsColors,
  socialButtonsColorsHover,
  socialButtonsColorsLight,
} from "../_global";
import { mediaQuery } from "../_breakpoints";
import { Link } from "gatsby";

const SocialLink = ({
  name,
  backgroundColor,
  href,
  isActive,
  activeColor,
  disabled,
  to,
  id,
  smallButton,
  className,
  ...rest
}) => {
  if (to) {
    return (
      <StyledGatsbyLink
        {...rest}
        id={id}
        className={className}
        to={disabled ? "" : to}
        disabled={disabled}
        activeColor={
          activeColor ||
          socialButtonsColors[name] ||
          socialButtonsColors.default
        }
        background={
          backgroundColor ||
          socialButtonsColorsLight[name] ||
          socialButtonsColorsLight.default
        }
        border={socialButtonsColors[name] || socialButtonsColors.default}
      >
        {name}
      </StyledGatsbyLink>
    );
  }

  return (
    <StyledLink
      {...rest}
      className={className}
      href={disabled ? "" : href}
      disabled={disabled}
      background={
        backgroundColor ||
        socialButtonsColorsLight[name] ||
        socialButtonsColorsLight.default
      }
      activeColor={
        activeColor || socialButtonsColors[name] || socialButtonsColors.default
      }
      border={socialButtonsColors[name] || socialButtonsColors.default}
    >
      {name}
    </StyledLink>
  );
};

const StyledLink = styled.a.attrs({ target: "_blank" })`
  display: inline-grid;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  outline: none;
  background: ${(props) => {
    if (props.disabled) {
      return "#e2e2e2";
    }
    if (props.isActive) {
      if (props.activeColor) {
        return props.activeColor;
      }
      return socialButtonsColors.default;
    }
    return props.background;
  }};
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  color: black;
  font-weight: 700;
  line-height: 16px;
  padding: 6px 16px;
  font-size: 12px;
  border: 2px solid ${(props) => props.border};
  &:hover {
    background: ${(props) => {
      if (props.disabled) {
        return "#e2e2e2";
      }
      if (props.hoverColor) {
        return props.hoverColor;
      }
      return socialButtonsColorsHover.default;
    }};
  }
  ${mediaQuery.sm(`
    font-size: 10px;
    height: 10px;
      font-size: 14px;
    line-height: 10px;
    padding: 10px 13px;
    width: auto;
  `)}
`;

const StyledGatsbyLink = styled(Link)`
  display: inline-grid;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  outline: none;
  background: ${(props) => {
    if (props.disabled) {
      return "#e2e2e2";
    }
    if (props.isActive) {
      if (props.activeColor) {
        return props.activeColor;
      }
      return socialButtonsColors.default;
    }
    return props.background;
  }};
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  color: black;
  font-weight: 700;
  line-height: 16px;
  padding: 6px 16px;
  font-size: 12px;
  border: 2px solid ${(props) => props.border};
  &:hover {
    background: ${(props) => {
      if (props.disabled) {
        return "#e2e2e2";
      }
      if (props.hoverColor) {
        return props.hoverColor;
      }
      return socialButtonsColorsHover.default;
    }};
  }
  ${mediaQuery.sm(`
    font-size: 10px;
    height: 10px;
  font-size: 14px;
    line-height: 10px;
    padding: 10px 13px;
    width: auto;
  `)}
`;

export default SocialLink;
